import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
    const location = useLocation();

    return (
        <div className="bg-white shadow">
            <div className="max-w-4xl mx-auto px-4">
                <div className="flex h-16">
                    <div className="flex space-x-8">
                        <Link
                            to="/"
                            className={`inline-flex items-center px-3 py-2 border-b-2 text-sm font-medium ${
                                location.pathname === '/'
                                    ? 'border-blue-600 text-blue-600'
                                    : 'border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-300'
                            }`}
                        >
                            CV
                        </Link>
                        <Link
                            to="/blog"
                            className={`inline-flex items-center px-3 py-2 border-b-2 text-sm font-medium ${
                                location.pathname.startsWith('/blog')
                                    ? 'border-blue-600 text-blue-600'
                                    : 'border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-300'
                            }`}
                        >
                            Blog
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigation;