

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './Navigation';
import CVLandingPage from './CVLandingPage';
import BlogApp from './BlogApp';
import {AuthProvider} from "./AuthContext";
import {LoginPage} from "./Login";

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
                    <Navigation />
                    <Routes>
                        <Route path="/" element={<CVLandingPage />} />
                        <Route path="/blog/*" element={<BlogApp />} />
                        <Route path="/login" element={<LoginPage />} />
                    </Routes>
                </div>
            </AuthProvider>
        </Router>
    );
};



export default App;